<template>
    <div id="reappointments">
        <h1>Reappointments</h1>
        <div class="auth" v-if="authenticated">
            <div class="md-layout md-gutter md-alignment-top-center reappointment-table">
                <div class="md-layout-item md-size-90">
                    <md-table v-model="searched" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
                              :md-sort-fn="itemSort" md-card md-fixed-header>
                        <md-table-toolbar>
                            <div class="md-toolbar-section-start">
                            </div>
                            <md-field md-clearable class="md-toolbar-section-end">
                                <md-input placeholder="Search for text..." v-model="search"/>
                            </md-field>
                        </md-table-toolbar>

                        <md-table-empty-state v-if="!loaded"
                                              md-label="Loading"
                                              :md-description="`Loading Reappointments`">
                            <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                        </md-table-empty-state>

                        <md-table-empty-state v-else md-label="No Reappointments found">
                            <p v-if="search">
                                No reappointments found for this query: {{search}} . Try a different search term.
                            </p>
                            <p v-else>
                                You do not have any reappointments listed for your department.
                            </p>
                        </md-table-empty-state>

                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Name" md-sort-by="lastName">{{ item.lastName }}, {{item.firstName}}</md-table-cell>
                            <md-table-cell md-label="Department/Unit" md-sort-by="orgDescr">{{ item.orgDescr }}</md-table-cell>
                            <md-table-cell md-label="Title" md-sort-by="jobTitleShort">{{ item.jobTitleShort }}</md-table-cell>
                            <md-table-cell md-label="End Date" md-sort-by="endDate">{{ displayDate(item.endDate) }}</md-table-cell>
                            <md-table-cell>
                                <md-button @click="handleSaveCase(item)" class="md-raised md-primary"> Kickoff Case </md-button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                </div>
            </div>
            <div id="create-new-warning-reapp">
                <md-dialog-confirm
                  :md-active.sync="showCreateNewDialog"
                  md-title="Warning: You have an open case"
                  md-content="You currently have an incomplete case open. Would you like to delete that case and start a new one using this reappointment?"
                  md-confirm-text="Clear & Create New"
                  md-cancel-text="Continue Working on Open Case"
                  @md-cancel="caseRouter()"
                  @md-confirm="caseRouter(true)" />
            </div>
        </div>
    </div>
</template>
<script>
  import {LOAD_STATUS} from "../store/store-utils"

  const toLower = text => {
    return text.toString().toLowerCase();
  }

  const searchByText = (items, term) => {
    if (term) {
      return items.filter(item => toLower(JSON.stringify(item)).includes(toLower(term)));
    }
    return items;
  };

  const customSort = (items, field, direction) => {
    return items.sort((a, b) => {
      let val1 = a[field] ? a[field] : "zzzz";
      let val2 = b[field] ? b[field] : "zzzz";

      if (direction === 'desc') {
        return val2.localeCompare(val1)
      }
      return val1.localeCompare(val2)
    })
  };
  export default {
    name: "Reappointments.vue",
    data: function()  {
      return {
        currentSort: "lastName",
        currentSortOrder: "asc",
        search: null,
        loginUrl:  process.env.VUE_APP_SIGNIN_URL,
        showCreateNewDialog: false,
        kickableCase: null
      }
    },
    computed: {
      authenticated() {
        return this.$store.getters['user/authenticated']
      },
      searched: {
        get: function () {
          let reappointments = this.$store.getters['sasData/reappointments'];
          return customSort(searchByText(reappointments, this.search), this.currentSort, this.currentSortOrder);
        },
        set: function () {
          //do nothing
        }
      },
      loaded() {
        return this.$store.getters['sasData/reappointmentLoadStatus'] === LOAD_STATUS.LOADED;
      },
      loadStatus() {
        return this.$store.getters['sasData/reappointmentLoadStatus'];
      }
    },
    methods: {
      itemSort(value) {
        return customSort(value, this.currentSort, this.currentSortOrder);
      },
      displayDate(date) {
        return date.substr(5,2) + "/" + date.substr(8,2) + "/" + date.substr(0,4);
      },
      handleSaveCase(reappointment) {
          this.kickableCase = reappointment;
          if (localStorage.getItem('caseKickoffForm')){
              this.showCreateNewDialog = true;
          } else {
              this.showCreateNewDialog = false;
              this.caseRouter(true);
          }
      },
      caseRouter(newCase = false) {
          if (newCase) {
              this.$store.dispatch('form/initiateFromReappointment', this.kickableCase, {root: true});
              this.$emit('newCaseRouter', false, "", true);
          } else {
              this.$emit('newCaseRouter');
          }
      }
    }
  }
</script>

<style >
    .reappointment-table .md-table-head {
        text-align: center;
    }

</style>
